<template>
    <v-layout justify-center>
        <v-flex xs12 sm8 md4>
            <v-card flat>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-text-field
                            outline clearable
                            counter=10
                            v-model="name"
                            name="name"
                            :label="$t('changeName.show-name')"
                            type="text"
                            :rules="rules"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn large block class="subheading" color="primary" :disabled="!valid" @click="submit">{{ $t('changeName.submit') }}</v-btn>
                </v-card-actions>
            </v-card>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'

export default {
    name: 'ChangeName',
    components: { WaitingDialog },
    mixins: [WaitDialogControl],
    data() {
        // console.log(i18n.t('register.rules.nameRules.emptyErr'))
        return {
            valid: false,
            name: '',
            rules: [
                v => !!v || i18n.t('changeName.rules.emptyErr'),
                v => (v && v.length <= 10) || i18n.t('changeName.rules.maxLengthErr')
            ]
        }
    },
    methods: {
        init() {
            this.name = store.getters.user.name
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.showDialog('更新資料', '更新失敗')
                let url = HostUrl + '/api/user/profile'
                let payload = { name: this.name }
                var self = this
                axios.patch(url, payload, getAxiosConfig())
                    .then(function (response) {
                        if (response.data.code === 0) {
                            store.commit('setSnackBar', { message: '完成資料更新', color: 'success' })
                            store.commit('setUserProfile', response.data.profile)
                            self.$router.back()
                        } else store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                    })
                    .catch(function (error) {
                        console.error('update name API failed', error)
                        store.commit('setSnackBar', { message: '修改資料失敗, 請稍後再試', color: 'error' })
                    })
                    .finally(function () {
                        self.closeDialog()
                    })
            }
        }
    },
    activated() {
        store.commit('setTitle', 'changeName.nav-change-name')
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
    }
}
</script>

<style scoped>
</style>